import React from "react";
import { Link } from "gatsby";
import Sidebar from "./components/sidebar";
import Footer from "./components/footer";

import "../styles/main.scss";


const FAQPage = () => (
  <div className="container">
    <div className="inner">
      <Sidebar pageContext="faq" />
      <section id="faqs" className="faqs">
        <h1 className="ultra-condensed-44">Faqs</h1>
        <div className="faqs__content">
          <div className="faqs__content-question bold-16">
            Where can I go to find more information on health insurance plans and
            how much subsidy I am eligible for?
          </div>
          <div className="faqs__content-answer">
            You can go directly to BCBS Plan websites
          </div>
          <div className="faqs__content-question bold-16">
            What are some of the biggest benefits of new tax credits under ARPA?
          </div>
          <div className="faqs__content-answer">
            The ARPA tax credits significantly increased subsidies for most
            consumers purchasing individual market ACA plans on Federal and
            state exchanges. Current estimates predict that 80% of enrollees
            will qualify for a plan for $10 or less a month after tax credits,
            and over 50% will be able to find a Silver plan for $10 or less. 1
            out of 4 current enrollees will be able to upgrade to a higher plan
            category that offers covers more out of pocket costs at the same or
            lower premiums compared to what they’re paying today.
          </div>
        </div>
        <div className="faqs__return">
          <Link className="btn btn--white bold-14" to="/">
            Return to Calculator
          </Link>
        </div>
        <Footer />
      </section>
    </div>
  </div>
);

export default FAQPage;
